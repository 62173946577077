.consultoria{
    
    display: flex;
    justify-content: center;
    &_container{
        width: 100%;
        
        max-width: $containerWidth;
        &_flex{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            @media screen and (max-width: 1200px){
                justify-content: flex-start;
                flex-wrap: wrap;
            }
        }
        h3{
            text-align: center;
        }
        ul{
            list-style: none;
            background-color: $alternative;
            padding: 33px;
            padding-top: 23px;
            border-radius: 23px;
            li{
                margin-bottom: 23px;
                
                img{
                    margin-bottom: -6px;
                }
            }
        }
    }
}