.banner{
  width: 100%;
  max-height: 500px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    width: 100%;
    object-fit: fill;
    @media screen and (max-width: 850px){
      width: auto;
      height: 100%;
    }

  }
  &_text{
    position: absolute;
    color: white;
    width: 1000px;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: 1000px){
      width: 100%;
      padding: 34px;
      box-sizing: border-box;
    }

    h3{
      font-size: 40px;
      @media screen and (max-width: 1000px){
        font-size: 34px;
      }
      @media screen and (max-width: 750px){
        font-size: 24px;
      }
    }
    &-mision{
      font-weight: 100;
      font-size: 24px;
    }
  }
}
