.recovery{ 
    border: 1px solid $primary;
    height: 100vw;
    height: 100vh;
    background-color: $primary;
    display: flex;
    justify-content: center;
    &_container{
        box-sizing: content-box;
        border-radius: 4px;
        width: 100%;
        max-width: 500px;
        margin: 16px;
        margin-top: 200px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        background-color: white;
        &_form{
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            input{
              height: 30px;
              border-radius: 15px;
              padding-left: 16px;
            }
            label{
              font-size: 16px;
      
            }
            button{
              background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
              font-size: 14px;
              background-color: $primary;
              height: 40px;
              color: white;
              margin-top: 45px;
              border-radius: 15px;
              margin-bottom: 0px
      
            }
            h2{
              text-align: center;
              padding-bottom: 34px;
            }
        }
    }
}