.playersCard{
  width: 100%;
  display: flex;
  justify-content: center;
  &_title{
    text-align: center;
  }
  &_count{
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 42px;
    font-size: 14px;
    font-weight: lighter;
    margin-top: -16px;
  }
  &_container{
    padding: 23px;
    z-index: -1;
    width: 100%;
    max-width: 700px;
    height: 150px;
    display: flex;
    margin-bottom: 32px;
    gap: 16px;
    @media screen and (max-width: 1000px){
      flex-wrap: wrap;
      height: 100%;
    }
    &_img{
      width: 360px;
      height: 150px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      img{
        width: 100%;
        position: relative;
        object-position: center center;
      }
    }
    &_text{
      display: flex;
      flex-direction: column;
      gap: 18px;
      &_position{
        background-color: $secundary;
        border-radius: 6px;
        color: white;
        font-size: 13px;
        display: flex;
        justify-content: center;
        padding: 6px 18px;
        width: 100px;
      }
      &_name{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
      }
      &_city{
        display: flex;
        gap: 5px;
        font-size: 15px;
        font-weight: lighter;
        color: #848484;
      }
      &_club{
        &_pais{
          font-weight: lighter;;
          color: #848484;
          font-size: 14px;
        }
      }
    }
  }
}
