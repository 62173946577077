.sn_form{
    &_header{
        display: flex;
        justify-content: space-between;
        &_title{
            font-size: 23px;
        }
        &_close{
            border: 1px solid black;
            padding-top: 6px;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;
        }
    }
    &_btn{
        background-color: $secundary;
        width: 100%;
        border: none;
        padding: 9px;
        font-weight: 900;
        color: white;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
        &:hover{
            background-color: $primary;
        }
    }
    border: 1px solid rgb(211, 211, 211);
    margin: 23px;
    padding: 23px;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    
    label{
        font-size: 12px;
        display: block;
    }
    input, select, textarea{
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 16px;
    }
    textarea{
        height: 239px;
    }
    &_gird{
        
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 34px;
        gap: 23px;
        @media screen and (max-width: 1000px){
            grid-template-columns: 1fr; 
        }
        &_cm{
         
        }
    }
}