.card{
  padding-top: 150px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  &_card{
    //border: 1px solid red;
    width: 100%;
    padding-bottom: 50px;
    max-width: 1000px;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.4);
    border-radius: 16px;
    margin: 23px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
    grid-template-areas: "data"
                         "button";
    &_data{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "text position image";
      grid-area: data;
      padding: 30px;
      //padding-top: 56px;
      //display: flex;
      //justify-content: space-between;
      //gap: 60px;
      //display: flex;
      align-items: center;
      @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 300px;
        grid-template-areas: "image"
                             "text" 
                             "position";
        //flex-direction: column-reverse;
      }
      &_text{
        grid-area: text;
        justify-self: center;
        &_player{
          width: 300px;
          border-radius: 12px;
        }
        &-club{
          font-size: 13px;
          font-weight: lighter;
          margin-top: -23px;
          color: $secundary;
        }
        &-description{
          font-size: 15px;
          font-weight: normal;
          margin-bottom: 40px;
          width: 350px;
        }
        &-data{
          font-size: 15px;
          font-weight: lighter;
          color: black;
          margin-top: -16px;
          span{
            color: $secundary;
          }
        }
      }
      &_position{
        padding: 16px;
        display: flex;
        justify-content: center;
        grid-area: position;
        img{
          @media screen and (max-width: 1000px){
            transform: rotate(90deg);
            //flex-direction: column-reverse;
          }
        }
      }
      &_image{
        display: flex;
        justify-content: center;
        grid-area: image;
        
        
      }
    }
    &_botonera{
      grid-area: button;
      display: flex;
      justify-content: center;
      gap: 16px;
    }
  }
}
