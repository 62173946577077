.hero{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 84px);
  min-height: 700px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &_text{
    position: absolute;
    color: white;
    width: 100%;
    max-width: 1440px;
    padding: 16px;
    box-sizing: border-box;
    top: 80px;
    @media screen and (max-width: 1000px){
      top: 170px;
    }
    h1{
      font-size: 72px;
      width: 100%;
      @media screen and (max-width: 1000px){
        font-size: 54px;
      }
    }
    p{
      max-width: 550px;
      font-size: 18px;
      line-height: 33px;
      font-weight: normal;
      margin-top: -30px;
    }
  }
}
.transparentBlock{
  width: 100%;
  height: 10px;
  background-color: white;
}
