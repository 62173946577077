.about{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: calc(8*8px);

  &_container{

    margin-top: calc(8*8px);
    width: 100%;
    max-width: $containerWidth;
    display: flex;
    @media screen and (max-width: 1000px){
      flex-direction: column;
      justify-content: center;
    }
    justify-content: center;
    gap: 32px;
    &_img{
      
      img{
        width: 100%;
      }
      @media screen and (max-width: 1000px){
        display: flex;
        justify-content: center;
      }
    }
    &_text{
      width: 490px;
      h3{
        @media screen and (max-width: 1000px){
          margin: 0;
        }
      }
      @media screen and (max-width: 1000px){
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100vw;
        padding: 32px;
        box-sizing: border-box;
      }
    }
  }
}
.left{
  text-align: right;
  @media screen and (max-width: 1000px){
    text-align: left;
  }
}
.otro{
  @media screen and (max-width: 1000px){
    flex-direction: column-reverse;
  }
}
