.services{
  display: flex;
  justify-content: center;
  &_container{
    width: 100%;
    max-width: $containerWidth;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 64px;
    h3{
      text-align: center;
      width: 100%;
      @media screen and (max-width: 950px){
        font-size: 34px;
        padding-top: 45px;
        margin-bottom: 89px;
      }
    }
    &_enum{

      width: 100%;
      max-width: 800px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      @media screen and (max-width: 950px){
        flex-direction: column;
        align-items: center;
      }

      &_pill{
        background-color: #FBFBFB;
        width: 350px;
        padding: 16px;
        display: flex;
        gap: 32px;
        img{
          display: inline-block;
          width: 50px;
          height: 50px;
        }
        p{
          display: inline-block;
        }
      }
    }
  }

}
