.plan{
  display: flex;
  justify-content: center;
  &_container{
    width: 100%;
    max-width: $containerWidth;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &_pills{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: center;
      padding-top: 80px;
    }
    &_title{
      width: 800px;
      padding: 34px;
      h3{
        text-transform: uppercase;
        font-size: 48px;
        text-align: center;
        margin-bottom: -10px;
        @media screen and (max-width: 1000px){
          font-size: 34px;
        }
      }
      p{
        margin: 0 auto;
        padding-top: 20px;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        line-height: 170%;
        @media screen and (max-width: 750px){
          width: 90%;
        }
      }
    }
  }
}
