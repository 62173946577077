//@import 'bootstrap/dist/css/bootstrap.min.css';
@import "./variables.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./hero.scss";
@import "./commmon.scss";
@import "./about.scss";
@import "./banner.scss";
@import "./services.scss";
@import "./plan.scss";
@import "./pill.scss";
@import "./map.scss";
@import './player.scss';
@import './login.scss';
@import './playersCard.scss';
@import './private.scss';
@import './card.scss';
@import './downbanner.scss';
@import './forms.scss';
@import './cpanel.scss';
@import './recovery.scss';


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap');


body{
  background-color: $white;
  font-family: $primaryFont;
  font-weight: bold;
  scroll-behavior: smooth;
}
h3{
  font-weight: 900;
  font-size: 48px;
  line-height: 130%;
}
p{
  font-weight: bold;
  line-height: 170%;
}

.links-none{
  text-decoration: none;
  color: inherit;
}
a:visited{
  color: inherit;
}
.btn2{
  background-color: $secundary;
  color: white;
  font-size: 14px;
  width: 183px;
  height: 38px;
}
.botoneraFiltros{
  display: flex;
  gap:16px
}
.btn_filter{
  color: white;
  height: 44px;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}