.private{
  &_sections{
    
    width: 100%;
    padding-top: 100px;
    display: grid;
    grid-template-columns: 500px 1fr;
    @media screen and (max-width: 1200px){
      grid-template-columns: 1fr;
    }
    &_fields{
      border: 1px solid lightgrey;
      border-width: 0px 2px 0px 0px;
      &_flex{
        box-sizing: border-box;
        width: 380px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        position: fixed;
        @media screen and (max-width: 1200px){
          width: 100%;
          padding: 32px;
          background-color: white;
          margin-top: -34px;
          gap:8px;
        }
      }
    }
    &_list{
      
      @media screen and (max-width: 1200px){
        margin-top: 420px;
      }
    }
  }
}
