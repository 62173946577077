table.blueTable {

  border: 1px solid #1C6EA4;
  background-color: #C5E9F1;
  width: 100%;
  height: 100px;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td, table.blueTable th {
  border: 0px solid #AAAAAA;
  padding: 3px 2px;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #F5F5F5;
}
table.blueTable thead {
  background: #091524;
  border-bottom: 4px solid #287BCA;
}
table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 0px solid #D0E4F5;
  height: 40px;
}
table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
}
table.blueTable tfoot td {
  font-size: 14px;
}
table.blueTable tfoot .links {
  text-align: right;
}
table.blueTable tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

.player_search{
  width: 90%;
  height: 40px;
  border-radius: 20px;
  border-color: #ddd;
  font-size: 18px;
  padding: 0 16px;

}
.player_container{
  padding-top: 56px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 32px;
  h4{
    text-align: center;
    width: 100%;
  }
}
