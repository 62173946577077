.cpanel{
    &_botonera{
        
        display: flex;
        gap: 23px;
        justify-content: center;
    }
    &_titles{
        h2{
            text-align: center;
            margin-top: 43px;
        }
        p{
            text-align: center;
            width: 100vw;
            font-size: 14px;;
        }
        
    }
}